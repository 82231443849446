import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  // baseURL = 'http://localhost:3000';
  baseURL = 'https://tito-v2-api.herokuapp.com';
  constructor(private http: HttpClient) {

  }

  get(url: string) {
    return this.http.get(this.baseURL + url).pipe(map(data => data));
  }

  post(url: string, data: any) {
    return this.http.post(this.baseURL + url, data);
  }

  put(url: string, data: any) {
    return this.http.put(this.baseURL + url, data);
  }

  delete(url: string) {
    return this.http.delete(this.baseURL + url);
  }

}
